























































import { Component, Prop, Vue } from 'vue-property-decorator'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import { Content } from '@bertazzoni/common/src/models/content.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'

@Component({
  components: { PaginatedTable, Loader, Icons }
})
export default class AssociateContent extends Vue {
  @Prop({ default: () => [] }) alreadySelected!: string[]
  @Prop({ default: '' }) language!: string
  private loading = false
  private filterKey = ''
  private search = ''
  private contentList: Content[] = []
  private selectedContentList: string[] = JSON.parse(JSON.stringify(this.alreadySelected))
  private tableInfo: TableInfo = new TableInfo()

  async created() {
    this.tableInfo.getCustomTable().displayButton = false
    await this.getContentList()
  }
  async getContentList(): Promise<void> {
    this.loading = true
    const result = await ContentService.findAllPaginated({
      limit: this.tableInfo.paginInfo.limit,
      offset: this.tableInfo.paginInfo.offset,
      title: this.search,
      language: this.language
    })
    this.contentList = result.results
    this.tableInfo.paginInfo.totalRow = result.total
    this.loading = false
  }
  searchChanged() {
    this.search = this.filterKey
    this.newPage(0, 1)
    this.getContentList()
  }
  close() {
    this.$emit('close')
  }
  setAssociatedList(newValue: boolean, selectedId: string) {
    if (newValue) {
      this.selectedContentList.splice(
        this.selectedContentList.findIndex((element: string) => element === selectedId),
        1
      )
    } else {
      this.selectedContentList.push(selectedId)
    }
  }
  confirmation() {
    this.$emit('submit', this.selectedContentList)
  }
  async newLimit(limit: number) {
    this.tableInfo.paginInfo.limit = limit
    this.newPage(0, 1)
  }
  async newPage(offset: number, currentPage: number) {
    this.tableInfo.paginInfo.offset = offset
    this.tableInfo.paginInfo.currentPage = currentPage
    await this.getContentList()
  }
}
