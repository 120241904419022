import axios from 'axios'
import { Tag } from '@bertazzoni/common/src/models/tag.model'
import { buildUrl, PaginationData, buildTagUrl } from '@bertazzoni/common/src/helpers/urlFactory'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/tags'

class TagsService {
  async findAll(): Promise<Tag[]> {
    const response = await axios.get(`${API_URL}/findAll`)
    return response.data
  }
  async getAllTagCategories(): Promise<string[]> {
    const response = await axios.get(`${API_URL}/getAllTagCategories`)
    return response.data
  }
  async findAllPaginated(
    data: PaginationData
  ): Promise<{ results: Tag[]; total: number; offset: number; limit: number }> {
    let url = `${API_URL}/findAllPaginated/filter?limit=${data.limit}`
    url = buildUrl(data, url)
    const response = await axios.get(url)
    return response.data
  }
  async createNewCategory(category: { id: string; tagNames: string[] }): Promise<void> {
    let url = `${API_URL}/createNewCategory/${category.id}`
    url = buildTagUrl(category.tagNames, url)
    const response = await axios.post(url)
    return response.data
  }
  async assignNewTagsTo(category: { id: string; tagNames: string[] }): Promise<string> {
    let url = `${API_URL}/assignNewTagsTo/${category.id}`
    url = buildTagUrl(category.tagNames, url)
    const response = await axios.put(url)
    return response.data
  }
}

export default new TagsService()
