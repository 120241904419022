














































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmationModal extends Vue {
  @Prop({ required: true }) objectName!: string
  @Prop({ required: true }) action!: string
  private loading = false
  close() {
    this.$emit('close')
  }
  confirmation() {
    this.$emit('confirmation')
  }
}
