





























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Question, QuestionType } from '@bertazzoni/common/src/models/Question'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import RadioButton from '@bertazzoni/common/src/components/RadioButton.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'

@Component({
  components: {
    Icons,
    CheckBox,
    RadioButton
  }
})
export default class QuizEdit extends Vue {
  @Prop({ default: null }) editQuestion!: Question
  private newQuestion: Question = new Question()
  private focused = false
  private questionType = QuestionType
  private keyCheckbox = 0
  private showNewProposal: boolean | number = false

  created(): void {
    if (this.editQuestion) {
      this.newQuestion = JSON.parse(JSON.stringify(this.editQuestion))
    }
  }
  mounted() {
    document.getElementById('new-question').focus({ preventScroll: false })
  }
  close(): void {
    this.$emit('close')
  }
  newAnswer(): void {
    this.newQuestion.proposals.push('')
    this.showNewProposal = this.newQuestion.proposals.length - 1
    this.$nextTick(() => {
      document.getElementById('new-proposal').focus({ preventScroll: false })
    })
  }
  submit(): void {
    if (this.editQuestion) {
      this.$emit('edit-question', this.newQuestion)
    } else {
      this.$emit('add-question', this.newQuestion)
    }
  }
  setRadioButton(newValue: number): void {
    this.newQuestion.indexResponses = 0
    this.newQuestion.indexResponses = newValue
  }
  setCheckBox(index: number): void {
    if (typeof this.newQuestion.indexResponses !== 'number') {
      if (this.newQuestion.indexResponses.includes(index)) {
        this.newQuestion.indexResponses.splice(
          this.newQuestion.indexResponses.findIndex((indexRep: number) => indexRep === index),
          1
        )
      } else {
        this.newQuestion.indexResponses.push(index)
      }
    }
  }
  deleteProposal(index: number): void {
    this.newQuestion.proposals.splice(index, 1)
    if (
      this.newQuestion.type === this.questionType.qcm &&
      typeof this.newQuestion.indexResponses !== 'number'
    ) {
      if (this.newQuestion.indexResponses.find((indexRep: number) => indexRep === index)) {
        this.newQuestion.indexResponses.splice(
          this.newQuestion.indexResponses.findIndex((indexRep: number) => indexRep === index),
          1
        )
      }
      //TODO: Needs to be refact
      for (let i = index; i < this.newQuestion.indexResponses.length; i++) {
        this.newQuestion.indexResponses[i] -= 1
      }
      this.keyCheckbox++
    } else {
      if (this.newQuestion.indexResponses === index) this.newQuestion.indexResponses = 0
    }
  }

  @Watch('newQuestion.type')
  changeQuestionType(newValue: string, oldValue: string): void {
    if (!this.editQuestion || oldValue) {
      //Si c'est une création ou si c'est une édition et que c'est la première fois que le type change
      if (newValue === this.questionType.vf) {
        //Si le nouveau type est V/F
        this.newQuestion.proposals = ['True', 'False']
        this.showNewProposal = false
        //On cache le bouton pour rajouter des propositions
      } else {
        //Si le nouveau type est différent de V/F
        if (oldValue === this.questionType.vf) {
          //Si l'ancient type est V/F
          this.newQuestion.proposals = ['', '']
        }
      }
    }
    if (newValue === this.questionType.qcm) this.newQuestion.indexResponses = [0]
    else this.newQuestion.indexResponses = 0
  }
}
