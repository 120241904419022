


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Contact } from '@bertazzoni/common/src/models/module.model'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
@Component({
  components: { Icons, FormError }
})
export default class ContactEdit extends Vue {
  @Prop({ required: true }) contactList!: Contact[]
  private contacts = this.contactList

  async setContacts() {
    this.$emit('set-contacts-list', this.contacts)
  }
  async addNewContact() {
    if (this.contacts && this.contacts.length > 0) {
      const validList = []
      for (let index = 0; index < this.contacts.length; index++) {
        validList.push(this.$validator.validate(`contactName${index}`))
        validList.push(this.$validator.validate(`email${index}`))
      }
      const results = Promise.all(validList)
      const areValid = (await results).every((isValid) => isValid)
      if (areValid) {
        this.contacts.push({ name: '', email: '' })
        this.setContacts()
      }
    } else {
      this.contacts.push({ name: '', email: '' })
      this.setContacts()
    }
  }
  async deleteContact(index: number) {
    this.contacts.splice(index, 1)
    this.setContacts()
  }

  @Watch('contactList')
  getContacts(newValue: Contact[]) {
    this.contacts = newValue
  }
}
