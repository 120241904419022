import axios from 'axios'
import { Mod, NewModuleDto, UpdateModuleDto } from '@bertazzoni/common/src/models/module.model'
import { buildUrl, PaginationData } from '@bertazzoni/common/src/helpers/urlFactory'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/modules'

class ModulesService {
  async findAll(): Promise<Mod[]> {
    const response = await axios.get(`${API_URL}/findAll`)
    return response.data
  }

  async findOne(id: string): Promise<Mod> {
    const response = await axios.get(`${API_URL}/findOne/${id}`)
    return response.data
  }

  async findAllPaginated(
    data: PaginationData
  ): Promise<{ results: Mod[]; total: number; offset: number; limit: number }> {
    let url = `${API_URL}/findAllPaginated/filter?limit=${data.limit}`
    url = buildUrl(data, url)
    const response = await axios.get(url)
    return response.data
  }

  async getNamesByIds(ids: string[]): Promise<{ name: string; id: string }[]> {
    const response = await axios.get(`${API_URL}/getNamesByIds?ids=${ids}`)
    return response.data
  }

  async removeOne(moduleId: string): Promise<void> {
    return await axios.delete(`${API_URL}/removeOne/${moduleId}`)
  }
  async createOne(module: NewModuleDto): Promise<void> {
    return await axios.post(`${API_URL}/createOne`, module)
  }
  async updateOne(module: UpdateModuleDto, id: string): Promise<Mod> {
    return await axios.put(`${API_URL}/updateOne/${id}`, module)
  }

  async getAllFromUser(tag?: { tags: string[] }): Promise<Mod[]> {
    let url = `${API_URL}/getAllFromUser`
    if (tag) url += `?tags=${tag.tags}`
    const response = await axios.get(url)
    return response.data
  }
}

export default new ModulesService()
