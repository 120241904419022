import { Question } from '@bertazzoni/common/src/models/Question'
export class Mod {
  _id: string
  title: string
  description: string
  language: string
  tags: string[]
  contentIDs: string[]
  accessEntityIds: string[]
  createdAt: Date
  updatedAt: Date
  startingDate: Date
  expirationDate: Date
  imageUrl: string
  quiz: Question[]
  keyBenefits: string[]
  contacts: Contact[]
  followed: boolean
  score?: number
  finishedContent: number
  finishDate: Date
}
export class NewModuleDto {
  title!: string
  description?: string
  language!: string
  expirationDate?: Date
  startingDate?: Date
  tags!: string[] // 1 min
  keyBenefits!: string[] // 1 min 6max
  accessEntityIds!: string[]
  contentIDs!: string[] //1 min 5max
  imageUrl!: string //à checker
  quiz!: Question[] // 5 questions
  contacts?: Contact[] // si renseigné, name et email obligatoire

  constructor(
    keyBenefits: string[] = [''],
    contacts: Contact[] = [{ name: '', email: '' }],
    tags: string[] = [],
    language = ''
  ) {
    this.keyBenefits = keyBenefits
    this.contacts = contacts
    this.tags = tags
    this.language = language
  }
}
export class UpdateModuleDto {
  title: string
  description: string
  language: string
  expirationDate: Date
  startingDate: Date
  tags: string[] // 1 min
  keyBenefits: string[] // 1 min 6max
  accessEntityIds: string[]
  contentIDs: string[] //1 min 5max
  imageUrl: string //à checker
  quiz: Question[] // 5 questions
  contacts: Contact[] // si renseigné, name et email obligatoire

  constructor(
    title?: string,
    description?: string,
    language?: string,
    expirationDate?: Date,
    startingDate?: Date,
    tags?: string[],
    keyBenefits?: string[],
    accessEntityIds?: string[],
    contentIDs?: string[],
    imageUrl?: string,
    quiz?: Question[],
    contacts?: Contact[]
  ) {
    Object.assign(this, {
      title,
      description,
      language,
      expirationDate,
      startingDate,
      tags,
      keyBenefits,
      accessEntityIds,
      contentIDs,
      imageUrl,
      quiz,
      contacts
    })
  }
}

export class Contact {
  name!: string
  email!: string

  constructor(name = '', email = '') {
    this.name = name
    this.email = email
  }
}
