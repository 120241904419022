






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TagList from '@bertazzoni/back/components/TagList.vue'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { TagAccess, EntityType } from '@bertazzoni/common/src/models/entity.model'
import { UserType } from '@bertazzoni/common/src/models/user.model'

@Component({
  components: {
    TagList
  }
})
export default class AccessFilter extends Vue {
  @Prop({ default: null }) initAccessList!: string[]
  @Prop({ default: false }) readonly!: string[]
  @Prop({ default: false }) titleLess!: string
  private tagCountryList: TagAccess[] = []
  private AutoCompCountry: string[] = []
  private filteredAutoCompCountry: string[] = []
  private tagDistributorList: TagAccess[] = []
  private distributorList: TagAccess[] = []
  private AutoCompDistrib: string[] = []
  private filteredAutoCompDistrib: string[] = []
  private tagDealerList: TagAccess[] = []
  private dealerList: TagAccess[] = []
  private AutoCompDealer: string[] = []
  private filteredAutoCompDealer: string[] = []
  private submitAccessList: string[] = []
  private usertype: UserType = UserType.seller
  private usertypeEnum = UserType
  private initAuto = 0

  async created(): Promise<void> {
    await this.getUserType()
    if (this.usertype === this.usertypeEnum.bertazzoniAdmin) {
      const countryPromise = EntitiesService.findEntitiesByType(EntityType.country)
      const distributorPromise = EntitiesService.findEntitiesByType(EntityType.distributor)
      const dealerPromise = EntitiesService.findEntitiesByType(EntityType.dealer)
      const data = await Promise.all([countryPromise, distributorPromise, dealerPromise])
      this.AutoCompCountry = data[0]
      this.AutoCompDistrib = data[1]
      this.AutoCompDealer = data[2]
    } else {
      const distributorPromise = await EntitiesService.findEntitiesByType(EntityType.distributor)
      const dealerPromise = EntitiesService.findEntitiesByType(EntityType.dealer)
      const data = await Promise.all([distributorPromise, dealerPromise])
      this.AutoCompDistrib = data[0]
      this.AutoCompDealer = data[1]
    }
  }
  async selectTagCountry(tag: string): Promise<void> {
    this.tagCountryList.push({ _id: tag, parents: [] })
    //Si il y a des tags associés à celui ajouté on les delete pour mettre un All qui les regroupes tous
    this.tagDistributorList.map((distrib: TagAccess) => {
      if (distrib._id !== 'All' && distrib.parents && distrib.parents.includes(tag)) {
        this.tagDistributorList.splice(this.tagDistributorList.indexOf(distrib), 1)
        this.distributorList.splice(this.distributorList.indexOf(distrib), 1)
      }
    })
    this.tagDealerList.map((dealer: TagAccess) => {
      if (dealer.parents.includes(tag)) {
        this.tagDealerList.splice(this.tagDealerList.indexOf(dealer), 1)
      }
    })
    this.dealerList.map((dealer: TagAccess) => {
      if (dealer.parents.includes(tag)) {
        this.dealerList.splice(this.dealerList.indexOf(dealer), 1)
      }
    })
    //On récupère les enfant grace au service
    let childList = await EntitiesService.findEntitiesByType(EntityType.distributor, tag)
    // Si il n'y a pas de tag distrib All avec comme parent le country sélectionné, on le crée
    if (
      !this.tagDistributorList.some(
        (tagDistrib: TagAccess) => tagDistrib._id === 'All' && tagDistrib.parents.includes(tag)
      )
    ) {
      this.tagDistributorList.push({ _id: 'All', parents: [tag] })
      childList.map((childDistrib: string) => {
        //On check qu'il n'y ai pas déja des tags similaires sans parent
        this.tagDistributorList.map((tagDistrib: TagAccess) => {
          if (tagDistrib._id === childDistrib) {
            this.tagDistributorList.splice(this.tagDistributorList.indexOf(tagDistrib), 1)
            this.distributorList.splice(this.distributorList.indexOf(tagDistrib), 1)
          }
        })
        this.distributorList.push({ _id: childDistrib, parents: [tag] })
      })
    }
    childList.map((childDistrib: string) => {
      //Check qu'il n'y ai pas de All dans dealer aparenté au même tag
      if (this.tagDealerList && this.tagDealerList.length > 0) {
        this.tagDealerList.map((tagDealer: TagAccess) => {
          if (tagDealer._id === 'All' && tagDealer.parents.includes(childDistrib)) {
            this.tagDealerList.splice(this.tagDealerList.indexOf(tagDealer), 1)
            this.dealerList.map((dealer: TagAccess) => {
              if (dealer.parents.includes(childDistrib)) {
                this.dealerList.splice(this.dealerList.indexOf(dealer), 1)
              }
            })
          }
        })
      }
    })

    //On crée le tag All des dealer et on le lie à tous les parents et grand parents
    this.tagDealerList.push({ _id: 'All', parents: [tag] })
    childList.map((childDistrib: string) => {
      this.tagDealerList[this.tagDealerList.length - 1].parents.push(childDistrib)
    })
    //On récupère les enfants grace au service
    childList = await EntitiesService.findEntitiesByType(EntityType.dealer, tag)
    childList.map((childDealer: string) => {
      if (this.tagDealerList && this.tagDealerList.length > 0) {
        //On check qu'il n'y ai pas déja des tags similaires sans parent
        this.tagDealerList.map((tagDealer: TagAccess) => {
          if (tagDealer._id === childDealer) {
            this.tagDealerList.splice(this.tagDealerList.indexOf(tagDealer), 1)
          }
        })
        this.dealerList.map((dealer: TagAccess) => {
          if (dealer._id === childDealer) {
            this.dealerList.splice(this.dealerList.indexOf(dealer), 1)
          }
        })
      }
      this.dealerList.push({ _id: childDealer, parents: [] })
      this.dealerList[this.dealerList.length - 1].parents = this.tagDealerList[
        this.tagDealerList.length - 1
      ].parents
    })
    this.setSubmitList()
  }
  async getUserType(): Promise<void> {
    this.usertype = this.$store.getters['User/currentUser'].type
  }
  removeTagCountry(country: TagAccess): void {
    this.tagCountryList.splice(this.tagCountryList.indexOf(country), 1)
    //Si dans les distrib il y a un tag avec country comme parent, on l'éclate
    this.tagDistributorList.map((distrib: TagAccess) => {
      if (distrib._id === 'All' && distrib.parents.includes(country._id)) {
        // Puis on cherche les tags contenu dans le All
        const allContainList = this.findTagsInAll(this.distributorList, distrib)
        if (allContainList && allContainList.length > 0)
          // Et on les insère à l'endroit du All qui vient d'être supprimé
          allContainList.map((distrib: TagAccess) => {
            this.tagDistributorList.splice(this.tagDistributorList.indexOf(distrib), 0, distrib)
          })
        this.tagDistributorList.splice(this.tagDistributorList.indexOf(distrib), 1)
      }
    })
    this.setSubmitList()
  }
  async selectTagDistributor(tag: string): Promise<void> {
    this.tagDistributorList.push({ _id: tag, parents: [] })
    this.distributorList.push({ _id: tag, parents: [] })

    // Si il n'y a pas de tag distrib All avec comme parent le country sélectionné, on le crée
    if (
      !this.tagDealerList.some(
        (tagDealer: TagAccess) => tagDealer._id === 'All' && tagDealer.parents.includes(tag)
      )
    ) {
      this.tagDealerList.push({ _id: 'All', parents: [tag] })
      const childList = await EntitiesService.findEntitiesByType(EntityType.dealer, tag)
      childList.map((childDealer: string) => {
        this.tagDealerList.map((tagDealer: TagAccess) => {
          //On check qu'il n'y ai pas déja des tags similaires sans parent
          if (tagDealer._id === childDealer) {
            this.tagDealerList.splice(this.tagDealerList.indexOf(tagDealer), 1)
            this.dealerList.splice(this.dealerList.indexOf(tagDealer), 1)
          }
        })
        this.dealerList.push({ _id: childDealer, parents: [tag] })
        this.dealerList[this.dealerList.length - 1].parents = this.tagDealerList[
          this.tagDealerList.length - 1
        ].parents
      })
    }
    this.setSubmitList()
  }
  async removeTagDistributor(distrib: TagAccess): Promise<void> {
    // Si le tag supprimé est un All et qu'il a un ou plusieurs parents, on delete tous les parents
    if (distrib._id === 'All' && distrib.parents && distrib.parents.length > 0) {
      if (this.usertype === this.usertypeEnum.bertazzoniAdmin) {
        this.tagCountryList = this.tagCountryList.filter((country: TagAccess) => {
          return !distrib.parents.includes(country._id)
        })
      }
      // Puis on cherche les tags contenu dans le All
      const allContainList = this.findTagsInAll(this.distributorList, distrib)
      if (allContainList && allContainList.length > 0)
        // Et on les insère à l'endroit du All qui vient d'être supprimé
        allContainList.map((distrib: TagAccess) => {
          this.tagDistributorList.splice(this.tagDistributorList.indexOf(distrib), 0, distrib)
        })
    } else {
      this.distributorList.splice(this.tagDistributorList.indexOf(distrib), 1)
      //Si dans les dealer il y a un tag avec distrib comme parent, on l'éclate
      this.tagDealerList.map((dealer: TagAccess) => {
        if (dealer._id === 'All' && dealer.parents.includes(distrib._id)) {
          //On cherche les tags contenu dans le All
          const allContainList = this.findTagsInAll(this.dealerList, dealer)
          if (allContainList && allContainList.length > 0)
            // Et on les insère à l'endroit du All qui vient d'être supprimé
            allContainList.map((dealer: TagAccess) => {
              this.tagDealerList.splice(this.tagDealerList.indexOf(dealer), 0, dealer)
            })
          this.tagDealerList.splice(this.tagDealerList.indexOf(dealer), 1)
        }
      })
    }
    this.tagDistributorList.splice(this.tagDistributorList.indexOf(distrib), 1)

    this.setSubmitList()
  }

  selectTagDealer(tag: string): void {
    this.tagDealerList.push({ _id: tag, parents: [] })
    this.dealerList.push({ _id: tag, parents: [] })
    this.setSubmitList()
  }
  async removeTagDealer(dealer: TagAccess): Promise<void> {
    //Si on éclate un tag All on vide tous les tags des parents correspondants
    if (dealer._id === 'All' && dealer.parents && dealer.parents.length > 0) {
      if (this.usertype === this.usertypeEnum.bertazzoniAdmin) {
        this.tagCountryList = this.tagCountryList.filter((country: TagAccess) => {
          return !dealer.parents.includes(country._id)
        })
      }

      this.tagDistributorList = this.tagDistributorList.filter((distrib: TagAccess) => {
        return !dealer.parents.includes(distrib._id)
      })
      this.tagDistributorList.map((tagDistrib: TagAccess) => {
        if (
          tagDistrib._id === 'All' &&
          dealer.parents.some((parent: string) => tagDistrib.parents.includes(parent))
        ) {
          this.tagDistributorList.splice(this.tagDealerList.indexOf(tagDistrib), 1)
        }
      })

      this.distributorList = this.distributorList.filter((distrib: TagAccess) => {
        return !dealer.parents.includes(distrib._id)
      })
      const allContainList = this.findTagsInAll(this.dealerList, dealer)
      if (allContainList && allContainList.length > 0)
        allContainList.map((dealer: TagAccess) => {
          this.tagDealerList.splice(this.tagDistributorList.indexOf(dealer), 0, dealer)
        })
    } else {
      this.dealerList.splice(this.dealerList.indexOf(dealer), 1)
    }
    this.tagDealerList.splice(this.tagDealerList.indexOf(dealer), 1)
    this.setSubmitList()
  }
  resetAll(): void {
    this.tagCountryList = []
    this.tagDistributorList = []
    this.distributorList = []
    this.tagDealerList = []
    this.dealerList = []
    this.setSubmitList()
  }
  findTagsInAll(list: TagAccess[], entity: TagAccess): TagAccess[] {
    //On trouve tout les tag contenu dans le 'All' pour les faire apparaitre
    let allContainList = []
    list.map((realAccess: TagAccess) => {
      entity.parents.map((removedParent: string) => {
        if (realAccess.parents.includes(removedParent)) {
          allContainList.push(realAccess)
        }
      })
    })
    allContainList = allContainList.filter(function(elem, index, self) {
      return index === self.indexOf(elem)
    })
    return allContainList
  }
  getNewAutoCountry(autoList: string[]): void {
    this.filteredAutoCompCountry = autoList
    this.initAuto++
  }
  getNewAutoDistrib(autoList: string[]): void {
    this.filteredAutoCompDistrib = autoList
    this.initAuto++
  }
  getNewAutoDealer(autoList: string[]): void {
    this.filteredAutoCompDealer = autoList
    this.initAuto++
  }
  @Watch('initAuto')
  setAccessFilter(newValue: number) {
    if ((this.usertype !== this.usertypeEnum.bertazzoniAdmin && newValue === 2) || newValue === 3) {
      if (this.initAccessList && this.initAccessList.length > 0) {
        this.initAccessList.map((tag: string) => {
          if (this.filteredAutoCompCountry.includes(tag)) {
            this.selectTagCountry(tag)
          } else if (this.filteredAutoCompDistrib.includes(tag)) {
            this.selectTagDistributor(tag)
          } else if (this.filteredAutoCompDealer.includes(tag)) {
            this.selectTagDealer(tag)
          }
        })
      }
    }
  }
  setSubmitList(): void {
    this.submitAccessList = []
    if (this.usertype === this.usertypeEnum.bertazzoniAdmin) {
      this.submitAccessList = this.submitAccessList.concat(
        this.tagCountryList.map((country: TagAccess) => country._id)
      )
    }
    this.submitAccessList = this.submitAccessList.concat(
      this.tagDistributorList
        .filter((distrib: TagAccess) => distrib._id !== 'All')
        .map((distrib: TagAccess) => distrib._id)
    )
    this.submitAccessList = this.submitAccessList.concat(
      this.tagDealerList
        .filter((dealer: TagAccess) => dealer._id !== 'All')
        .map((dealer: TagAccess) => dealer._id)
    )
    this.$emit('edit-access-list', this.submitAccessList)
  }
}
